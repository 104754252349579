import React, {useEffect} from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";
import axios from "../api";
import {connect} from 'react-redux'
import {postVisit} from '../store/action'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    },
    title: {
        color: '#ffff',
        marginBottom: 80
    },
    subTitle: {
        color: '#ffff',
        marginBottom: '2.35em'
    },
    selectRoot: {
        color: '#fff',
        '&:hover:not($disabled):before,&:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiIconButton-label': {
            color: '#fff'
        },
        '& .MuiInputBase-root': {
            color: '#fff'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.45)'
        }
    },
    inputContainer: {
        marginBottom: 15
    },
    disabledButton: {
        backgroundColor: 'rgb(224,224,224) !important'
    }
}));
const data = [
    {
        title: 'Vendor',
    },
    {
        title: 'Business Partner'
    },
    {
        title: 'Friend'
    },
    {
        title: 'Job Interview'
    },
    {
        title: 'Contractor'
    }

]
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        },
        secondary: {
            main: '#1976D2',
            contrastText: '#fff'
        },
        default: {
            main: '#fff'
        }
    },
});

function Visit(props) {
    const classes = useStyles();
    const [selectedHost, getHost] = React.useState(null);
    const [host, setHost] = React.useState()
    const [typeOfVisit, setTypeOfVisit] = React.useState({})
    const [selectedVisitStatus, setSelectedVisit] = React.useState({})
    const [smsNotificationTypeId, setNotifTypeId] = React.useState({})
    useEffect(() => {
        axios.get('/v1/visitor-management/visit-types/').then(response => {
            setHost(response.data)
            let visit = props.visit_status.find(row => {
                return row.name === 'Pending'
            })
            let notif = props.notificationtypes.find(row => {
                return row.name === 'SMS'
            })
            setSelectedVisit(visit.id || null)
            setNotifTypeId(notif.id || null)
            setTypeOfVisit(response.data[0].id)

        })
    }, [])

    const selectHost = (event, data) => {
        getHost(data)
    }
    const onNext = (e) => {
        e.preventDefault()
        props.history.push('/user-detail')
        return
        // for api integration
        let data = {
            visit_type: typeOfVisit,
            visit_status: selectedVisitStatus,
            notification_type: smsNotificationTypeId
        }
        props.postVisit(data).then(response => {
            if (response.status === 201) {
                props.history.push('/user-detail')
            }
        })
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Paper elevation={0} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            Who Are you visiting ?
                        </Typography>
                        <div className={classes.inputContainer}>
                            <Autocomplete
                                id="size-small-standard"
                                className={classes.selectRoot}
                                placeholder="Select"
                                size="small"
                                options={host}
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select Host"
                                        placeholder="Favorites"
                                        fullWidth

                                    />
                                )}
                                onChange={selectHost}/>
                        </div>
                        <Grid container spacing={2}>
                            <ThemeProvider theme={theme}>
                                <Grid item xs={6}>
                                    <Button fullWidth
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            onClick={() => {
                                                props.history.goBack()
                                            }}
                                    className={classes.marginButton}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={!selectedHost}
                                            classes={{disabled: classes.disabledButton}}
                                            onClick={onNext}
                                            className={classes.marginButton}
                                    >

                                        Continue
                                    </Button>

                                </Grid>
                            </ThemeProvider>

                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>

    )
}

function mapStateToProps(state) {
    return {
        visit_status: state.userDetail.visit_status,
        notificationtypes: state.userDetail.notification_type
    }
}

export default connect(mapStateToProps, {postVisit})(Visit);