import React from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import Webcam from "react-webcam";
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux'
import {uploadImage, updateVisitData} from "../store/action";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    },
    title: {
        color: '#ffff'
    },
    subTitle: {
        color: '#ffff',
        marginBottom: '2.35em'
    },
    selectRoot: {
        color: '#fff',
        '&:hover:not($disabled):before,&:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiIconButton-label': {
            color: '#fff'
        },
        '& .MuiInputBase-root': {
            color: '#fff'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.45)'
        }
    },
    inputContainer: {
        marginBottom: 15
    },
    imgContainer: {
        margin: 50
    }
}));
const data = [
    {
        title: 'Vendor',
    },
    {
        title: 'Business Partner'
    },
    {
        title: 'Friend'
    },
    {
        title: 'Job Interview'
    },
    {
        title: 'Contractor'
    }

]

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        },
        secondary: {
            main: '#4a32a8'
        }

    },
});

function Visit(props) {

    const classes = useStyles();
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });
    const [photo, setPhoto] = React.useState({
        isCapture: false,
        photo: null
    })
    const [canvas, setCanvas] = React.useState()
    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value,
        });
    };
    const webcamRef = React.useRef(null)
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log('imageSrc:', webcamRef, '----', webcamRef.current.ctx)
            setPhoto({
                isCapture: true,
                photo: imageSrc
            })
            let canvas = null
            webcamRef.current.canvas.toBlob(blob => {
                var date = new Date()
                var fileName = 'File-' + date.getFullYear() + '' + date.getMonth() + '' + date.getDate() + '' + date.getTime() + '.png'
                canvas = new File([blob], fileName, {lastModified: date, type: 'image/jpg'})
                setCanvas(canvas)
                console.log('canvas', canvas)
            })

        },
        [webcamRef]
    );

    const capturedPhoto = (
        <img src={photo.photo} width="500" className={classes.imgContainer}/>
    )

    const TakePhoto = () => {
        if (photo.isCapture) {
            props.history.push('/finish')
            return
            // for api integration

            if (canvas !== null) {
                props.uploadImage(canvas).then(response => {
                    if (response.status === 201) {
                        let updateData = {
                            id: props.visit.id,
                            image_id: response.data.id
                        }
                        props.updateVisitData(updateData).then(resp => {
                            props.history.push('/finish')
                        })
                    }
                })
            }

            return
        }
        capture()
    }
    const webCam = (
        <Webcam mirrored
                screenshotFormat="image/jpeg"
                width={500}
                height={500}
                ref={webcamRef}
                screenshotQuality={1}
                forceScreenshotSourceSize={true}
                audio={false}/>
    )
    const goBack = () => {
        if (photo.isCapture) {
            setPhoto({
                isCapture: false,
                photo: null
            })
            return
        }
        props.history.goBack()
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Paper elevation={0} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            Take a photo for your visitor pass
                        </Typography>
                        <div>
                            {photo.isCapture ? capturedPhoto : webCam}
                        </div>

                        <Grid container spacing={2}>
                            <ThemeProvider theme={theme}>
                                <Grid item xs={6}>
                                    <Button fullWidth
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            onClick={goBack}
                                            className={classes.marginButton}>
                                        {photo.isCapture ? 'Retake' : 'Back'}
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>

                                    <Button fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={TakePhoto}
                                            className={classes.marginButton}>
                                        {photo.isCapture ? 'Continue' : 'Take a photo'}
                                    </Button>
                                </Grid>
                            </ThemeProvider>

                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>

    )
}

function mapToState(state) {
    return {
        visit: state.userDetail.visit
    }
}

export default connect(mapToState, {uploadImage, updateVisitData})(Visit);