import axios from "../../api";

export const addDetail = payload => ({
    type: 'ADD_DETAILS',
    payload
})
export const createVisit = payload => ({
    type: 'SET_VISIT',
    payload
})

export const setVisit = payload => ({
    type: 'GET_VISIT_STATUS`',
    payload
})

export const getVisitStatus = () => dispatch => {
    axios.get('/v1/visitor-management/visit-status/').then(response => {
        if (response.status === 200 && response.statusText === 'OK') {
            dispatch({type: 'GET_VISIT_STATUS', payload: response.data})
        }
    })
}
export const getNotification = () => dispatch => {
    axios.get('/v1/visitor-management/notification-type/').then(response => {
        console.log('getNotification', response)
        if (response.status === 200 && response.statusText === 'OK') {
            dispatch({type: 'GET_NOTIFICATION_TYPE', payload: response.data})
        }
    })
}
export const postVisit = (data) => dispatch => {
    return axios.post('/v1/visitor-management/visits/',data).then(response => {
        dispatch({type: 'SET_VISIT', payload: response.data})
        return response
    })
}
export const uploadImage = (file) => dispatch => {
    let formData = new FormData()
    formData.set('title', Math.random().toString(36).substring(7))
    formData.set('extension', 'jpg')
    formData.set('file', file)
    return axios({
        method:'post',
        url:'/v1/assets/',
        data:formData,
        config:{ headers: {'Content-Type': 'multipart/form-data' }}
    }).then((response) => {
        console.log('response,',response)
        return response
    })
}
export const updateVisitData = (data) => dispatch => {
    return axios.put(`/v1/visitor-management/visits/${data.id}/`, {...data}).then(response => {
        dispatch({type: 'SET_VISIT', payload: response.data})
        return response
    })
}