import React from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, makeStyles, withStyles, ThemeProvider} from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';

const items = [
    '+93',
    '+358',
    '+355',
    '+213',
    '+1684',
    '+376',
    '+244',
    '+1264',
    '+672',
    '+1268',
    '+54',
    '+374',
    '+297',
    '+61',
    '+43',
    '+994',
    '+1242',
    '+973',
    '+880',
    '+1246',
    '+375',
    '+32',
    '+501',
    '+229',
    '+1441',
    '+975',
    '+591',
    '+387',
    '+267',
    '+55',
    '+246',
    '+673',
    '+359',
    '+226',
    '+257',
    '+855',
    '+237',
    '+1',
    '+238',
    '+345',
    '+236',
    '+235',
    '+56',
    '+86',
    '2+61',
    '+57',
    '+269',
    '+242',
    '+243',
    '+682',
    '+506',
    '+225',
    '+385',
    '+53',
    '+357',
    '+420',
    '+45',
    '+253',
    '+1767',
    '+1849',
    '+593',
    '+20',
    '+503',
    '+240',
    '+291',
    '+372',
    '+251',
    '+500',
    '+298',
    '+679',
    '+358',
    '+33',
    '+594',
    '+689',
    '+241',
    '+220',
    '+995',
    '+49',
    '+233',
    '+350',
    '+30',
    '+299',
    '+1473',
    '+590',
    '+1671',
    '+502',
    '+44',
    '+224',
    '+245',
    '+595',
    '+509',
    '+379',
    '+504',
    '+852',
    '+36',
    '+354',
    '+91',
    '+62',
    '+98',
    '+964',
    '+353',
    '+44',
    '+972',
    '+39',
    '+1876',
    '+81',
    '+44',
    '+962',
    '+77',
    '+254',
    '+686',
    '+850',
    '+82',
    '+965',
    '+996',
    '+856',
    '+371',
    '+961',
    '+266',
    '+231',
    '+218',
    '+423',
    '+370',
    '+352',
    '+853',
    '+389',
    '+261',
    '+265',
    '+60',
    '+960',
    '+223',
    '+356',
    '+692',
    '+596',
    '+222',
    '+230',
    '+262',
    '+52',
    '+691',
    '+373',
    '+377',
    '+976',
    '+382',
    '+1664',
    '+212',
    '+258',
    '+95',
    '+264',
    '+674',
    '+977',
    '+31',
    '+599',
    '+687',
    '+64',
    '+505',
    '+227',
    '+234',
    '+683',
    '+672',
    '+1670',
    '+47',
    '+968',
    '+92',
    '+680',
    '+970',
    '+507',
    '+675',
    '+595',
    '+51',
    '+63',
    '+872',
    '+48',
    '+351',
    '+1939',
    '+974',
    '+40',
    '+7',
    '+250',
    '+262',
    '+590',
    '+290',
    '+1869',
    '+1758',
    '+590',
    '+508',
    '+1784',
    '+685',
    '+378',
    '+239',
    '+966',
    '+221',
    '+381',
    '+248',
    '+232',
    '+65',
    '+421',
    '+386',
    '+677',
    '+252',
    '+27',
    '+211',
    '+500',
    '+34',
    '+94',
    '+249',
    '+597',
    '+47',
    '+268',
    '+46',
    '+41',
    '+963',
    '+886',
    '+992',
    '+255',
    '+66',
    '+670',
    '+228',
    '+690',
    '+676',
    '+1868',
    '+216',
    '+90',
    '+993',
    '+1649',
    '+688',
    '+256',
    '+380',
    '+971',
    '+44',
    '+1',
    '+598',
    '+998',
    '+678',
    '+58',
    '+84',
    '+1284',
    '+1340',
    '+681',
    '+967',
    '+260',
    '+263'
]

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    },
    title: {
        color: '#ffff',
        marginBottom: 80
    },
    subTitle: {
        color: '#ffff',
        marginBottom: '2.35em'
    },
    selectRoot: {
        color: '#fff',
        '&:hover:not($disabled):before,&:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiIconButton-label': {
            color: '#fff'
        },
        '& .MuiInputBase-root': {
            color: '#fff'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.45)'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingBottom: 4
        },
        marginRight: 15
    },
    inputContainer: {
        marginBottom: 15
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    formControlCustom: {
        display: 'block',
        textAlign: 'left'
    },
    numberCss: {
        width: '100%',
        display: 'flex',
        marginBottom: 25,
        marginTop: 15
    },
    passContainer: {
        position: 'relative',
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: 15
    },
    passHeader: {
        color: "#83BC47",
        backgroundColor: '#212121'
    },
    titleHeader: {
        textTransform: 'uppercase',
        fontSize: 20,
        fontWeight: 400,
        height: 50,
        paddingTop: 10
    },
    passlayout: {
        width: 400,
        backgroundColor: "#fff",
        borderBottom: "12px solid #83BC47"
    },
    tablePadd: {
        paddingLeft: 50
    },
    table: {
        width: '100%',
        textAlign: 'left'
    },
    tableBody: {},
    tr: {
        width: '40%'
    },
    td: {
        textAlign: 'center'
    },
    divider: {
        height: 5,
        backgroundColor: 'rgb(33, 33, 33)'
    },
    customButton: {
        width: '70%'
    },
    subtitle: {
        color: '#fff',
        marginBottom: 15
    }

}));

const data = [
    {
        title: 'Vendor',
    },
    {
        title: 'Business Partner'
    },
    {
        title: 'Friend'
    },
    {
        title: 'Job Interview'
    },
    {
        title: 'Contractor'
    }

]
const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#83BC47',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#83BC47',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#83BC47',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(255, 251, 251, 0.88)'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.54)'
        },
        '& .MuiInputBase-input': {
            color: '#fff'
        }
    },
})(TextField);
const CustomInput = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#83BC47',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#83BC47',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#83BC47',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(255, 251, 251, 0.88)'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.54)'
        },
        '& .MuiInputBase-input': {
            color: '#fff'
        }
    },
})(Input);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        },
        secondary: {
            main: '#4a32a8'
        }

    },
});

function FinishPage(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });
    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value,
        });
    };
    const [value, setValue] = React.useState()

    const handleOnChange = (e) => {

    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Paper elevation={0} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            All done
                        </Typography>
                        <Typography variant="subtitle1" className={classes.title}>
                            <span>John Done </span>
                            has been notified of your arrival. Please collect your pass from the printer below and place
                            it on your person whilst inside.
                        </Typography>
                        <div className={classes.passContainer}>
                            <div className={classes.passlayout}>
                                <div className={classes.passHeader}>
                                    <div className={classes.titleHeader}>
                                        Visitor
                                    </div>
                                </div>
                                <Typography variant="h5">
                                    John Doe
                                </Typography>
                                <Typography variant="subtitle2">
                                    Company
                                </Typography>
                                <Divider className={classes.divider}/>
                                <div className={classes.tablePadd}>
                                    <table className={classes.table}>
                                        <tbody>
                                        <tr>
                                            <td className={classes.tr}>
                                                Host
                                            </td>
                                            <td className={classes.td}>
                                                John Doe
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tr}>
                                                Valid until
                                            </td>
                                            <td className={classes.td}>
                                                test
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tr}>
                                                Signout code
                                            </td>
                                            <td className={classes.td}>
                                                12312
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Please refer to your pass for your sign out code
                        </Typography>
                        <ThemeProvider theme={theme}>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.customButton + ' ' + classes.marginButton}
                                    onClick={() => {
                                        props.history.push('/')
                                    }}>
                                Finish
                            </Button>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </Paper>
        </Container>

    )
}

export default FinishPage;