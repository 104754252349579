import React from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, makeStyles, withStyles, ThemeProvider} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import 'react-phone-number-input/style.css'
import Button from "@material-ui/core/Button";
import {useForm, Controller} from 'react-hook-form';
import PhoneNumberInput from "../components/phoneInput";
import {connect} from 'react-redux'
import {addDetail} from '../store/action'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    },
    title: {
        color: '#ffff',
        marginBottom: 80
    },
    subTitle: {
        color: '#ffff',
        marginBottom: '2.35em'
    },
    selectRoot: {
        color: '#fff',
        '&:hover:not($disabled):before,&:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid rgba(255, 251, 251, 0.88) !important'
        },
        '& .MuiIconButton-label': {
            color: '#fff'
        },
        '& .MuiInputBase-root': {
            color: '#fff'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.45)'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingBottom: 4
        },
        marginRight: 15
    },
    inputContainer: {
        marginBottom: 15
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    formControlCustom: {
        display: 'block',
        textAlign: 'left'
    },
    numberCss: {
        width: '100%',
        display: 'flex',
        marginBottom: 25,
        marginTop: 15
    },
    erorrMsg: {
        position: 'relative',
        color: '#f80000',
        display: 'flex',
    }
}));

const data = [
    {
        title: 'Vendor',
    },
    {
        title: 'Business Partner'
    },
    {
        title: 'Friend'
    },
    {
        title: 'Job Interview'
    },
    {
        title: 'Contractor'
    }

]
const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#83BC47',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#83BC47',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#83BC47',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(255, 251, 251, 0.88)'
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.54)'
        },
        '& .MuiInputBase-input': {
            color: '#fff'
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336'
        }
    },
})(TextField);
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        },
        secondary: {
            main: '#1976D2',
            contrastText: '#fff'
        },
        default: {
            main: '#fff'
        }
    },
});

function UserDetail(props) {
    console.log('props:', props.store)
    const classes = useStyles();
    const [state, setState] = React.useState({});
    const [value, setValue] = React.useState({fullName: '', companyName: ''})
    const {register, handleSubmit, control, errors, formState} = useForm()
    const onSubmit = (data) => {
        console.log('onsubmit', data, formState)

        // let details = {
        //     id: props.userDetail.visit.id,
        //     name:  data.fullName,
        //     visit_reason: 'Test Reason',
        //     attributes: {
        //         company: data.companyName
        //     }
        // }
        // props.store.dispatch(addDetail(data))
        setValue(data)
        if (formState.isValid) {
            if (data.fullName.trim() === '') {
                return
            } else if (data.companyName.trim() === '') {
                return
            }
            props.history.push('/photo')
        }
    }
    const getPhoneValue = (value) => {
        console.log('value', value)
        setState(value)
    }

    const ErrorMsg = (
        <span className={classes.erorrMsg}>This is Required.</span>
    )

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Paper elevation={0} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            Enter your details
                        </Typography>
                        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                as={<CustomTextField
                                    margin="normal"
                                    fullWidth
                                    id="name"
                                    autoComplete="name"
                                    autoFocus
                                    name="fullName"
                                    error={value.fullName === ''}
                                    ref={register({required: true})}
                                />}
                                name="fullName"
                                label="Full name"
                                control={control}
                                defaultValue=""
                                type="text"

                            />
                            {value.fullName.trim() === '' ? ErrorMsg : ''}
                            <Controller
                                as={<CustomTextField
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                    name="companyName"
                                    error={value.companyName === ''}
                                    ref={register({required: true})}/>}
                                id="company"
                                label="Company"
                                name="companyName"
                                autoComplete="company"
                                control={control}
                                defaultValue=""/>
                            {value.companyName.trim() === '' ? ErrorMsg : ''}
                            <PhoneNumberInput getValue={getPhoneValue}/>

                            <Grid container spacing={2}>
                                <ThemeProvider theme={theme}>
                                    <Grid item xs={6}>
                                        <Button fullWidth
                                                variant="outlined"
                                                color="primary"
                                                size="large"
                                                onClick={() => {
                                                    props.history.goBack()
                                                }}
                                                className={classes.marginButton}>
                                            Back
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button fullWidth
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                type="submit"
                                                className={classes.marginButton}>
                                            Continue
                                        </Button>

                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </Container>

    )
}

function mapStateToProps(state) {
    return {
        userDetail: state.userDetail
    }
}

export default connect(mapStateToProps)(UserDetail);