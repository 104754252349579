import React from 'react'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles, createMuiTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {ThemeProvider} from '@material-ui/styles';
import {connect} from 'react-redux'
import {getVisitStatus, getNotification} from '../store/action'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    },
    title: {
        color: '#ffff'
    },
    subTitle: {
        color: '#ffff',
        marginBottom: '2.35em'
    },
    secondarybtn: {
        color: '#c9c9c9f2'
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        },
        secondary: {
            main:'#83BC47',
            contrastText: '#c9c9c9f2'
        }
    },
});

function Main(props) {
    const classes = useStyles();
    const SignIn = (e) => {
        e.preventDefault()
        props.history.push('/visit')
    }

    React.useEffect(() => {
        props.getVisitStatus()
        props.getNotification()
    }, [])

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline/>
            <Paper className={classes.paper} elevation={0}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            Welcome to VSI
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom className={classes.subTitle}>
                            Sign in to contact your VSI host
                        </Typography>
                        <ThemeProvider theme={theme}>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.marginButton}
                                    onClick={SignIn}>
                                Sign In
                            </Button>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.marginButton}>
                                Sign Out
                            </Button>
                        </ThemeProvider>

                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = (state) => {
    return state
}

export default connect(mapDispatchToProps, {getVisitStatus, getNotification})(Main);