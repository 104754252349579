const initialState = {
    id: 0,
    name: '',
    visit_reason: '',
    attributes: {},
    person_to_visit: null,
    reason_for_visit: null,
    visit: null,
    visit_status: [],
    notification_type: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'ADD_DETAILS':
            return [
                ...state,
                {
                    id: action.id,
                    name: action.text,
                    visit_reason: action.visit_reason,
                    attributes: action.attributes
                }
            ]
        case 'SET_VISIT':
            console.log('SET_VISIT:',action)
            return {
                ...state,
                visit: action.payload
            }

        case 'GET_VISIT_STATUS':
            return {
                ...state,
                visit_status: action.payload
            }
        case'GET_NOTIFICATION_TYPE':
            return {
                ...state,
                notification_type: action.payload
            }
        default:
            return state
    }
}