import { createStore, compose, applyMiddleware} from "redux";
import { createBrowserHistory } from 'history';
import rootReducer from "./reducer";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const history = createBrowserHistory();
const enhancers = []
const initialState = {}
if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore() {
    const store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(thunk),...enhancers))
    const  persistor = persistStore(store)
    return {store, persistor };
}