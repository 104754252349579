import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Redirect,
    Route
} from "react-router-dom";
import Main from './views/Main'
import HeaderApp from "./components/Header";
import Visit from "./views/Visit";
import UserDetail from "./views/Details";
import PhotoId from "./views/PhotoId"
import FinishPage from './views/FinishPage'
import './assets/css/main.css'
import {createBrowserHistory} from 'history';
import {Provider} from "react-redux";
import configureStore from './store'
import {PersistGate} from 'redux-persist/integration/react'
import IdleModal from './components/IdleModal'

const history = createBrowserHistory();
const store = configureStore().store
const persistor = configureStore().persistor

function App(props) {
    const [open, setOpen] = React.useState(false);
    const [isIdle, setIdle] = React.useState(false)
    const [idleTimeout, setTimeOut] = React.useState(60000)
    const [idleCounter, setidleCounter] = React.useState(0)

    const resetIdle = () => {
        setidleCounter(0)
    }

    const onMove = () => {
        resetIdle()
    }
    const onTouch = () => {
        resetIdle()
    }

    const toggleModal = () => {
        setidleCounter(0)
        setOpen(!open)
    }
    const setIdleTime = (value) => {
        setidleCounter(idleCounter + value)
    }

    React.useEffect(() => {
        if (!open) {
            const idleServe = setInterval(() => {
                if (window.location.pathname === '/' || window.location.pathname === '/finish') {
                    return
                }
                if (idleCounter === idleTimeout) {

                toggleModal()
                    resetIdle()
                    clearInterval(idleServe);
                    return
                } else {
                    setidleCounter(idleCounter + 1000)
                }
            }, 1000)
            return () => clearInterval(idleServe);
        } else {
            return;
        }

    }, [open,idleCounter])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="App" onMouseMove={onMove} onTouchMove={onTouch}>
                    <HeaderApp/>
                    <IdleModal handleOpen={toggleModal} open={open} idleCounter={idleCounter} setIdle={setIdleTime}/>
                    <div className="mainContainer">
                        <Router history={history}>
                            <Route path="/" exact component={Main}/>
                            <Route path="/visit" exact component={Visit}/>
                            <Route path="/user-detail" exact component={UserDetail}/>
                            <Route path="/photo" exact component={PhotoId}/>
                            <Route path="/finish" exact component={FinishPage}/>
                            {/*<Redirect from="/" to="/"/>*/}
                        </Router>
                    </div>
                </div>
            </PersistGate>
        </Provider>
    );
}

export default App;
